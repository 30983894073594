import Head from "next/head";
import { useRouter } from "next/router";
import { GA_MEASUREMENT_ID, localServerUrl } from "../../constants/env";
import Script from "next/script";
import { Fragment, useEffect, useState } from "react";
import { Roboto } from "next/font/google";
import { useTranslation } from "next-i18next";
import { getCookie } from "cookies-next";
import CookieConsent from "vanilla-cookieconsent";

type InputProps = {
  title: string;
  description: string;
  url?: string;
  image?: string;
  SEOTitle?: string;
  SEODescription?: string;
  SEOKeyword?: string;
  type?: string;
  fullHead?: string;
};

const roboto = Roboto({
  weight: ["400", "900"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

export function MyHead({
  title,
  description,
  url,
  image,
  SEOTitle,
  SEODescription,
  SEOKeyword,
  type,
  fullHead,
}: InputProps) {
  const router = useRouter();
  const { i18n } = useTranslation("common");
  const [allowAnalytics, setAllowAnalytics] = useState<boolean>(false);

  useEffect(() => {
    const cc_cookie = getCookie("cc_cookie");
    if (cc_cookie && typeof cc_cookie === "string") {
      const consentCookie: CookieConsent.CookieValue = JSON.parse(cc_cookie);
      if (consentCookie.categories.includes("analytics"))
        setAllowAnalytics(true);
    }
  }, [getCookie("cc_cookie")]);

  function addHrefLang(locale?: string) {
    if (router.route === "/") {
      return `${locale && locale !== "pt" ? locale + "" : ""}`;
    } else {
      return `${locale && locale !== "pt" ? "/" + locale : ""}${router.route}/`;
    }
  }

  return (
    <Fragment>
      <style jsx global>{`
        html {
          font-family: ${roboto.style.fontFamily};
        }
      `}</style>
      {allowAnalytics && (
        <Script
          // strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        />
      )}
      {allowAnalytics && (
        <Script id="google-analytics" strategy="lazyOnload">
          {`
 window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${GA_MEASUREMENT_ID}');
        `}
        </Script>
      )}
      <Head>
        {fullHead && <Fragment>{fullHead}</Fragment>}
        {!fullHead && (
          <Fragment>
            <title>{`${title} - Psiworks`}</title>
            <link
              rel="alternate"
              hrefLang="x-default"
              href={localServerUrl + addHrefLang()}
            />
            <link
              rel="alternate"
              hrefLang="en"
              href={localServerUrl + addHrefLang("en")}
            />
            <link
              rel="alternate"
              hrefLang="pt"
              href={localServerUrl + addHrefLang("pt")}
            />
            <link
              rel="alternate"
              hrefLang="es"
              href={localServerUrl + addHrefLang("es")}
            />
            <meta
              name="google-site-verification"
              content="EJh1kiIoG4qxFFuWtMXVS55G9ZfnkNJM_IIeG9umCCw"
            />
            <meta name="keywords" content={SEOKeyword || ""} />
            <meta
              name="description"
              content={SEODescription || description}
              key="desc"
            />
            <meta
              property="og:title"
              content={SEOTitle || `Psiworks - ${title}`}
            />
            <meta property="og:locale" content={router.locale} />
            <meta
              property="og:description"
              content={SEODescription || description}
            />
            <meta
              property="og:url"
              content={`${localServerUrl}${
                router.asPath === "/" ? "" : router.asPath
              }`}
            />
            <meta property="og:site_name" content={i18n.t("psi").toString()} />
            <meta property="og:type" content={type || "website"} />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta name="geo.placename" content="Lisbon" />
            <meta name="geo.region" content="Portugal" />
            <link
              rel="canonical"
              href={localServerUrl + addHrefLang(router.locale)}
            />
            {/*<link*/}
            {/*  rel="canonical"*/}
            {/*  href={*/}
            {/*    router.asPath === "/"*/}
            {/*      ? localServerUrl*/}
            {/*      : `${localServerUrl}${router.asPath}`*/}
            {/*  }*/}
            {/*/>*/}
            <meta
              name="robots"
              content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
            />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/PsiWorksCuidadosDeSaude"
            />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1,maximum-scale=2,shrink-to-fit=no"
            />
            <meta name="twitter:card" content={description} />
            <meta
              property="og:image"
              content={
                image ||
                "https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/logo.png?alt=media&token=035b25fa-18e2-4fb9-bf28-7b22ed07f9d1"
              }
            />
            <meta
              property="og:image:secure_url"
              content={
                image ||
                "https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/logo.png?alt=media&token=035b25fa-18e2-4fb9-bf28-7b22ed07f9d1"
              }
            />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            <link rel="shortcut icon" href="/favicon.ico" />
          </Fragment>
        )}
      </Head>
      {allowAnalytics && (
        <Script
          /* Global Site Tag (gtag.js) - Google Analytics */
          // strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        />
      )}
      {allowAnalytics && !router.route.includes("agendar") && (
        <Script id="kommo">{`(function(a,m,o,c,r,m){a[m]={id:"787151",hash:"776def3fd8914306f0a3672476f28a30d6671528d373b2ed8188177392b8ed61",locale:"pt",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.kommo.com/js/button.js?1701252248';d.head&&d.head.appendChild(s)}(window,0,'crmPlugin',0,0,'crm_plugin'));`}</Script>
      )}
    </Fragment>
  );
}
